<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Copy Items</h3>

	<p>A System Admin, System Editor, Framework Admin, and Framework Editor have the ability and permissions to edit and copy items within a framework. </p>

	<p>Selecting the edit option to CREATE / COPY / IMPORT will produce options to choose items from the selected framework to duplicate.</p>

	<p>Choose the <nobr><v-icon small>fas fa-edit</v-icon></nobr> EDIT icon at the top of the framework. Click on any item in the framework and select the edit icon. Select   CREATE / COPY / IMPORT from the menu and choose COPY CHILD ITEMS FROM THIS OR ANOTHER FRAMEWORK
	</p>

	<p>The following pop up window will appear:</p>
	<img srcset="/docimages/copy_child_items.png 1.3x " class="k-help-img block">
	<p>Choose how you would like the duplicates to be copied and their association to the original items. Check off the items in the interface that you wish to copy, then click COPY SELECTED ITEMS. </p>

	<p>A confirmation dialog will appear to verify that you want to PROCEED. The message will read, <strong>This operation will DUPLICATE # selected item(s) under the current item. Do you wish to proceed?</strong></p>

	<p><strong>COPY CHILD ITEMS Options Explained:</strong></p>

	<p>When an item is copied, the Source Item Identifier of the original item becomes metadata of the copied item. If the copied item is edited, options appear to remove this tag breaking the link with the original item and add a Related To association from this item to the original item.

		<ul>
			<li><strong>Duplicate descendents…:</strong> in addition to the checked item itself, descendents of the item will also be duplicated, using the same hierarchical structure (i.e. including the same “Is Child Of” associations.) Note that if this option is chosen, you should not also check the item’s descendents.</li>
			<li><strong>Mark new items… with sourceItemIdentifier tags</strong> will automatically add the identifier (GUID) of each original item as the sourceItemIdentifier of each duplicated item. (A corresponding sourceItemURI tag will also be added for each duplicate.) This in turn allows Standards Satchel to keep track of what is a copy of what.
				<ul>
					<li>For example, if you later edit an item that was copied with sourceItemIdentifiers recorded, <span v-html="app_name"/> will automatically apply those edits to copies of the item.
						<ul>
							<li>However, if you edit the Full Statement of a duplicated item, the sourceItemIdentifier will be automatically cleared, so that later edits to the original will have no effect on the copy.</li>
							<li>If you edit item metadata other than the Full Statement in a duplicate, the sourceItemIdentifier will be retained; but in this case, if you later edit the original, only edits to the Full Statement will be automatically transferred to the duplicate.</li>
						</ul>
					</li>
					<li>If you do NOT desire this functionality (for example, if you’re duplicating the items with the express intention of subsequently editing the duplicates, as in use case 3 above), uncheck the <strong>Mark new items…</strong> option when creating the copy.</li>
				</ul>
			</li>
			<li><Strong>Add associations between copied items and original items</Strong> will automatically create an <strong>Exact Match</strong> or <Strong>Related To</Strong> association between copied and duplicated items (after clicking this box, you’ll see an additional option to choose which association type you want).
				<ul>
					<li>The default options are to add sourceItemIdentifier tags and not add associations. However, you could choose neither option, or you could choose both; all combinations of the two options are allowed.</li>
				</ul>
			</li>
			<li><Strong>Copy from</Strong> shows your selected framework, meaning that you’re invited to choose items previously created in this same framework to copy. Use the dropdown menu to select any other framework in Standards Satchel from which to duplicate items</li>
		</ul>
	</p>

	<p>Use Cases for Making Copies</p>

	<p>Use cases for duplicating items are myriad—e.g.:
		<ol>
			<li>Within a framework, the same standard might be repeated across multiple grade levels and/or in multiple courses. Since the standard may be taught differently in each grade/course, each copy of the standard should have a different identity (i.e. a different GUID).</li>
			<li>A standard originally authored as part of one framework may be used in other frameworks. For example, the NGSS science standards are used as the basis for many states’ science content standards. Since the CASE spec arguably demands that items in different frameworks cannot share the same GUID, duplicates of the NGSS standards, each with their own GUIDs, must be created for each state.</li>
			<li>When authoring a framework that has repeating hierarchical sets of items, it may be more efficient to duplicate a set of already-created items and then edit the duplicates, rather than recreating the hierarchy each time.</li>
		</ol>
	</p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	